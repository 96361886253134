import { StatusBar } from "expo-status-bar";
import React, {
  useState,
  useReducer,
  useMemo,
  useContext,
  useEffect,
} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Platform,
  StyleSheet,
  View,
  Alert,
  ActivityIndicator,
} from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";

import { Icon } from "react-native-elements";
import { FontAwesome } from "@expo/vector-icons";

import { Login } from "./Screens/Login";
import { Profile } from "./Screens/Profile";
import { Register } from "./Screens/Register";
import { Search } from "./Screens/Search";
import { Home } from "./Screens/Home";

import client from "./utils/api";
import { addXsrfToken } from "./utils/api";

import { AuthContext } from "./Components/context";

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function HomeTabs({ navigation }) {
  const { signOut } = useContext(AuthContext);

  const AsyncAlert = async () =>
    new Promise((resolve) => {
      Alert.alert(
        "Déconnexion",
        "Message",
        [
          {
            text: "ok",
            onPress: () => {
              resolve("YES");
            },
          },
        ],
        { cancelable: false }
      );
    });

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={{
        tabBarActiveTintColor: "#FF5733",
        tabBarLabelPosition: "below-icon",
      }}
    >
      <Tab.Screen
        name="Home"
        component={Home}
        options={({ navigation }) => ({
          tabBarLabel: "Home",
          tabBarIcon: ({ color, size }) => (
            <FontAwesome name="home" color={color} size={size} />
          ),
          headerRight: () => (
            <Icon
              onPress={signOut}
              type="ionicon"
              name={Platform.OS === "ios" ? "ios-exit" : "md-exit"}
            />
          ),
        })}
      />
      <Tab.Screen
        name="Search"
        component={Search}
        options={({ navigation }) => ({
          tabBarLabel: "Search",
          tabBarIcon: ({ color, size }) => (
            <FontAwesome name="search" color={color} size={size} />
          ),
          headerRight: () => (
            <Icon
              onPress={signOut}
              type="ionicon"
              name={Platform.OS === "ios" ? "ios-exit" : "md-exit"}
            />
          ),
        })}
      />
      <Tab.Screen
        name="Profil"
        component={Profile}
        options={({ navigation }) => ({
          tabBarLabel: "Profil",
          tabBarIcon: ({ color, size }) => (
            <FontAwesome name="user" color={color} size={size} />
          ),
          headerRight: () => (
            <Icon
              onPress={signOut}
              type="ionicon"
              name={Platform.OS === "ios" ? "ios-exit" : "md-exit"}
            />
          ),
        })}
      />
    </Tab.Navigator>
  );
}

export default function App() {
  const [userInfo, setUserInfo] = useState(null);

  const initialLoginState = {
    isLoading: false,
    userName: null,
    userToken: null,
    refreshToken: null,
    xsrfToken: null,
    email: null,
  };

  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case "RETRIEVE_TOKEN":
        return {
          ...prevState,
          userName: action.username,
          userToken: action.token,
          isLoading: false,
        };
      case "REFRESH_TOKEN":
        return {
          ...prevState,
          xsrfToken: action.xsrfToken,
          userToken: action.token,
          refreshToken: action.refreshToken,
          isLoading: false,
        };
      case "LOGIN":
        return {
          ...prevState,
          xsrfToken: action.xsrfToken,
          userToken: action.token,
          refreshToken: action.refreshToken,
          email: action.email,
          isLoading: false,
        };
      case "LOGOUT":
        return {
          ...prevState,
          userName: null,
          xsrfToken: null,
          userToken: null,
          refreshToken: null,
          email: null,
          isLoading: false,
        };
      case "REGISTER":
        return {
          ...prevState,
          userName: action.username,
          isLoading: false,
        };
    }
  };

  const [loginState, dispatch] = useReducer(loginReducer, initialLoginState);

  const authContext = useMemo(
    () => ({
      signIn: async (foundUser) => {
        setUserInfo(foundUser.userInfo);

        let userToken;
        userToken = null;
        let xsrfToken;
        xsrfToken = null;
        let refreshToken;
        refreshToken = null;
        let email;
        email = foundUser.userInfo.email;

        try {
          const response = await client.post("auth/login", {
            ...foundUser.userInfo,
          });
          if (response.status === 200) {
            // console.log(response);
            // console.log(response.data.accessToken);
            await AsyncStorage.setItem("email", email);
            await AsyncStorage.setItem("userToken", response.data.accessToken);
            await AsyncStorage.setItem("xsrfToken", response.data.xsrfToken);
            await AsyncStorage.setItem(
              "refreshToken",
              response.data.refreshToken
            );
            userToken = await AsyncStorage.getItem("userToken");
            xsrfToken = await AsyncStorage.getItem("xsrfToken");
            refreshToken = await AsyncStorage.getItem("refreshToken");
            console.log("userToken", userToken);
            console.log("xsrfToken", xsrfToken);
            console.log("refreshToken", refreshToken);
            // setIsLogin(true);
            // navigation.navigate("HomeTabs");
          }
        } catch (err) {
          console.log(err);
        }

        dispatch({
          type: "LOGIN",
          xsrfToken: xsrfToken,
          token: userToken,
          refreshToken: refreshToken,
          email: email,
        });
      },
      signOut: async () => {
        try {
          await AsyncStorage.multiRemove([
            "userToken",
            "refreshToken",
            "xsrfToken",
          ]);
        } catch (e) {
          console.log(e);
        }
        dispatch({ type: "LOGOUT" });
      },
      signUp: async (userInfo) => {
        let username;
        username = null;
        // console.log(userInfo);
        const res = await client.post("users", {
          ...userInfo.clone,
        });
        console.log("singup réponse", res);
        username = res.data.username;

        dispatch({
          type: "REGISTER",
          username: username,
        });
      },
      // toggleTheme: () => {
      //   setIsDarkTheme((isDarkTheme) => !isDarkTheme);
      // },
    }),
    []
  );

  useEffect(() => {
    let username;
    username = null;
    let refreshToken;
    refreshToken = null;
    let userToken;
    userToken = null;
    let email;
    email = null;
    setTimeout(async () => {
      try {
        userToken = await AsyncStorage.getItem("userToken");
        console.log("token", userToken);
        const value = await addXsrfToken(userToken);
        console.log("addXsrfToken", value);
        const response = await client.get("auth/me");
        username = response.data.username;
        await AsyncStorage.setItem("userName", username);
        console.log("réponse", response);
        if (response.status === 200) {
          console.log("réponse 200 pour la requete auth/me", response);
          // setIsLogin(true);
          console.log("userToken", userToken);

          dispatch({
            type: "RETRIEVE_TOKEN",
            username: username,
            token: userToken,
          });
          // navigation.navigate("HomeTabs");
        }
      } catch (err) {
        // console.log("ERREUR", err);
        console.log(err.response.data.statusCode);
        if (err.response.data.statusCode === 401) {
          try {
            refreshToken = await AsyncStorage.getItem("refreshToken");
            email = await AsyncStorage.getItem("email");
            console.log("email suite à erreur 401", email);
            const refresh = await client.post("auth/token", {
              email: email,
              refresh_token: refreshToken,
            });
            console.log(refresh);
            await AsyncStorage.setItem(
              "refreshToken",
              refresh.data.refreshToken
            );
            await AsyncStorage.setItem("userToken", refresh.data.accessToken);
            await AsyncStorage.setItem("xsrfToken", refresh.data.xsrfToken);

            dispatch({
              type: "REFRESH_TOKEN",
              xsrfToken: refresh.data.xsrfToken,
              userToken: refresh.data.accessToken,
              refreshToken: refresh.data.refreshToken,
            });
            // handlePress();
          } catch (error) {
            console.log(error);
            dispatch({ type: "LOGOUT" });
          }
        }
      }
    }, 1000);
  }, []);

  if (loginState.isLoading) {
    console.log(loginState);
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    // <View style={styles.main_container}>
    <AuthContext.Provider value={authContext}>
      <NavigationContainer>
        <StatusBar style="auto" />
        <Stack.Navigator>
          {loginState.userToken !== null ? (
            <Stack.Screen
              options={{
                headerShown: false,
              }}
              name="HomeTabs"
              component={HomeTabs}
            />
          ) : (
            <>
              <Stack.Screen
                options={{ headerShown: false }}
                name="Login"
                component={Login}
              />
              <Stack.Screen
                options={{ headerShown: true }}
                name="Register"
                component={Register}
              />
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </AuthContext.Provider>
    // </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    paddingLeft: 5,
  },
});
