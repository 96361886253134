import { StatusBar } from "expo-status-bar";
import React, { useState, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Alert,
} from "react-native";
import { isValidEmail, isValidObjField, updateError } from "../utils/methods";
import FormContainer from "./../Components/FormContainer";
import FormInput from "./../Components/FormInput";
import FormSubmitButton from "./../Components/FormSubmitButton";

import { Formik } from "formik";
import * as Yup from "yup";

import client from "../utils/api";
import { ScrollView } from "react-native-gesture-handler";

import { AuthContext } from "../Components/context";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 50,
  },
  containRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
  },
});

const ScreenContainer = ({ children }) => (
  <View style={styles.container}>{children}</View>
);

const validationSchema = Yup.object({
  firstname: Yup.string()
    .trim()
    .min(3, "Invalid name!")
    .required("Name is required!"),
  email: Yup.string().email("Invalid email!").required("Email is required!"),
  password: Yup.string()
    .trim()
    .min(6, "Password is too short!")
    .required("Password is required!"),
  confirmPassword: Yup.string().equals(
    [Yup.ref("password"), null],
    "Password does not match!"
  ),
});

export const Register = ({ navigation }) => {
  //   const { signUp } = React.useContext(AuthContext);

  const { signUp } = useContext(AuthContext);

  const userInfo = {
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const [error, setError] = useState("");

  const { firstname, lastname, username, email, password, confirmPassword } =
    userInfo;

  const handleOnChangeText = (value, fieldName) => {
    setUserInfo({ ...userInfo, [fieldName]: value });
  };

  const isValidForm = () => {
    // we will accept only if all of the fields have value
    if (!isValidObjField(userInfo))
      return updateError("Required all fields!", setError);
    // if valid name with 3 or more characters
    if (!username.trim() || fullname.length < 3)
      return updateError("Invalid username!", setError);
    // only valid email id is allowed
    if (!isValidEmail(email)) return updateError("Invalid email!", setError);
    // password must have 8 or more characters
    if (!password.trim() || password.length < 6)
      return updateError("Password is less then 6 characters!", setError);
    // password and confirm password must be the same
    if (password !== confirmPassword)
      return updateError("Password does not match!", setError);

    return true;
  };

  const sumbitForm = () => {
    if (isValidForm()) {
      // submit form
      console.log(userInfo);
    }
  };

  const signUpForm = async (values, formikActions) => {
    const clone = (({ confirmPassword, ...o }) => o)(values); // remove confirmPassword
    // console.log(clone);
    // console.log(values);

    signUp({ clone });
    // const res = await client.post("users", {
    //   ...clone,
    // });
    gt;
    console.log(res.data);
    if (res.data.success) {
      Alert.alert("Vous êtes bien enregistré!");
      navigation.navigate("Login");
    }

    formikActions.resetForm();
  };

  return (
    <ScreenContainer>
      <Image
        resizeMode="contain"
        style={{ width: 500, height: 200 }}
        source={require("./../assets/Welcome.png")}
      />
      <StatusBar style="auto" />
      <ScrollView>
        <FormContainer>
          <Formik
            initialValues={userInfo}
            validationSchema={validationSchema}
            onSubmit={signUpForm}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              const {
                firstname,
                lastname,
                username,
                email,
                password,
                confirmPassword,
              } = values;
              return (
                <>
                  <FormInput
                    value={firstname}
                    error={touched.firstname && errors.firstname}
                    onChangeText={handleChange("firstname")}
                    onBlur={handleBlur("firstname")}
                    label="Prénom"
                    placeholder="Entrez votre prénom"
                  />
                  <FormInput
                    value={lastname}
                    error={touched.lastname && errors.lastname}
                    onChangeText={handleChange("lastname")}
                    onBlur={handleBlur("lastname")}
                    label="Nom"
                    placeholder="Entrez votre Nom"
                  />
                  <FormInput
                    value={username}
                    error={touched.username && errors.username}
                    onChangeText={handleChange("username")}
                    onBlur={handleBlur("username")}
                    label="Pseudo"
                    placeholder="Entrez votre pseudo"
                  />
                  <FormInput
                    value={email}
                    error={touched.email && errors.email}
                    onChangeText={handleChange("email")}
                    onBlur={handleBlur("email")}
                    autoCapitalize="none"
                    label="Email"
                    placeholder="example@email.com"
                  />
                  <FormInput
                    value={password}
                    error={touched.password && errors.password}
                    onChangeText={handleChange("password")}
                    onBlur={handleBlur("password")}
                    autoCapitalize="none"
                    secureTextEntry
                    label="Password"
                    placeholder="********"
                  />
                  <FormInput
                    value={confirmPassword}
                    error={touched.confirmPassword && errors.confirmPassword}
                    onChangeText={handleChange("confirmPassword")}
                    onBlur={handleBlur("confirmPassword")}
                    autoCapitalize="none"
                    secureTextEntry
                    label="Confirm Password"
                    placeholder="********"
                  />
                  <FormSubmitButton
                    submitting={isSubmitting}
                    onPress={handleSubmit}
                    title="Sign up"
                  />
                </>
              );
            }}
          </Formik>
          <View style={styles.containRow}>
            <Text style={{ color: "black" }}>Déjà Inscrit ?</Text>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("Login");
              }}
            >
              <Text style={{ color: "blue" }}> Se connecter</Text>
            </TouchableOpacity>
          </View>
        </FormContainer>
      </ScrollView>
    </ScreenContainer>
  );
};
