import React from "react";
import { StyleSheet, View, Text, FlatList } from "react-native";
import { ListEmail } from "../Components/ListEmailUsers";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  containRow: {
    display: "flex",
    flexDirection: "row",
  },
});

const ScreenContainer = ({ children }) => (
  <View style={styles.container}>{children}</View>
);

export const Home = ({ navigation, listEmail }) => (
  <ScreenContainer>
    <Text>Liste des utilisateurs</Text>
    <ListEmail></ListEmail>
    {/* <Button
        title="React Native by Example"
        onPress={() =>
          navigation.push("Details", { name: "React Native by Example " })
        }
      />
      <Button
        title="React Native School"
        onPress={() =>
          navigation.push("Details", { name: "React Native School" })
        }
      />
      <Button title="Drawer" onPress={() => navigation.toggleDrawer()} /> */}
  </ScreenContainer>
);
