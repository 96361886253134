import axios from "axios";
import { API_BASE_URL } from "./constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
//Nous créons une instance axios afin de la ré-utiliser plus tard
//Pour ne pas à avoir à re renseigner l'url de base de notre api
//Ici, nous restons en local -> http://localhost:3000/api/v1/

const instance = axios.create({
  baseURL: API_BASE_URL,
});

export const addXsrfToken = async (token) => {
  //   const xsrfToken = getLocalStorageItem("user");
  console.log("inside async function addXrfToke");
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;
  const xsrfToken = await AsyncStorage.getItem("xsrfToken");
  console.log("xsrfToken", xsrfToken);
  instance.defaults.headers.common["x-xsrf-token"] = xsrfToken;
};

export default instance;
