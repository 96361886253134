import React, { useState, useContext } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StyleSheet, View, Text, TouchableOpacity, Image } from "react-native";

import { isValidEmail, isValidObjField, updateError } from "../utils/methods";
import FormContainer from "./../Components/FormContainer";
import FormInput from "./../Components/FormInput";
import FormSubmitButton from "./../Components/FormSubmitButton";

import { AuthContext } from "../Components/context";

import client from "../utils/api";
import * as Yup from "yup";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // paddingTop: 50,
  },
  containRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
  },
});

const ScreenContainer = ({ children }) => (
  <View style={styles.container}>{children}</View>
);

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email!").required("Email is required!"),
  password: Yup.string().trim().required("Password is required!"),
});

export const Login = ({ navigation }) => {
  const [error, setError] = useState("");
  // const { setIsLoggedIn, setProfile } = useLogin();
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });

  const [isLogin, setIsLogin] = useState(false);

  const { email, password } = userInfo;

  const { signIn } = useContext(AuthContext);

  const handleOnChangeText = (value, fieldName) => {
    setUserInfo({ ...userInfo, [fieldName]: value });
  };

  const isValidForm = () => {
    if (!isValidObjField(userInfo))
      return updateError("Required all fields!", setError);

    if (!isValidEmail(email)) return updateError("Invalid email!", setError);

    if (!password.trim() || password.length < 6)
      return updateError("Password is too short!", setError);

    return true;
  };

  const submitForm = () => {
    if (isValidForm()) {
      // console.log(userInfo);
      // await AsyncStorage.setItem("email", email);
      signIn({ userInfo });
    }
  };

  return (
    <ScreenContainer>
      <Image
        resizeMode="contain"
        style={{ width: "100%", height: "40%" }}
        source={require("./../assets/Img-Login.png")}
      />
      <FormContainer>
        {error ? (
          <Text style={{ color: "red", fontSize: 18, textAlign: "center" }}>
            {error}
          </Text>
        ) : null}
        <FormInput
          value={email}
          onChangeText={(value) => handleOnChangeText(value, "email")}
          label="Email"
          placeholder="example@email.com"
          autoCapitalize="none"
        />
        <FormInput
          value={password}
          onChangeText={(value) => handleOnChangeText(value, "password")}
          label="Password"
          placeholder="********"
          autoCapitalize="none"
          secureTextEntry
        />
        <FormSubmitButton onPress={submitForm} title="Login" />
        <View style={styles.containRow}>
          <Text style={{ color: "black" }}>Pas encore inscrit ?</Text>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("Register");
            }}
          >
            <Text style={{ color: "blue" }}> S'enregistrer</Text>
          </TouchableOpacity>
        </View>
      </FormContainer>
    </ScreenContainer>
  );
};
