import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  ScrollView,
} from "react-native";
// import { ScrollView } from "react-native-gesture-handler";
import client from "../utils/api";

export function ListEmail() {
  const [usersEmail, setUserEmail] = useState(null);

  // for (let i = 0; i < listEmail.data.length; i++) {
  //   console.log(listEmail.data[i].email);
  //   listEmailOnly = [...listEmail.data[i].email];
  // }
  // console.log(listEmailOnly);
  const getUserEmail = async () => {
    const listEmail = await client.get("users/public");
    console.log("listUsers", listEmail.data);
    setUserEmail(listEmail.data);
  };

  useEffect(() => {
    getUserEmail();
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={usersEmail}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => <Text>{item.email}</Text>}
      />
    </View>
  );
}
