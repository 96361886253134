import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, View, Text, Button, TouchableOpacity } from "react-native";
import client from "../utils/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "../Components/context";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  containRow: {
    display: "flex",
    flexDirection: "row",
  },
});

const ScreenContainer = ({ children }) => (
  <View style={styles.container}>{children}</View>
);

export const Profile = () => {
  //   const { signOut } = React.useContext(AuthContext);
  const { signOut } = useContext(AuthContext);
  const [userFind, setUserFound] = useState({});
  const getInfoUserProfil = async () => {
    const email = await AsyncStorage.getItem("email");
    console.log(email);
    const userProfileInfo = await client.get("users/email/" + email);
    setUserFound(userProfileInfo.data);
  };

  useEffect(() => {
    getInfoUserProfil();
  }, []);

  return (
    <ScreenContainer>
      <Text>Profile Screen</Text>
      <View>
        <Text>{"Prénom: " + userFind.firstname}</Text>
        <Text>{"Nom:" + userFind.lastname}</Text>
        <Text>{"Pseudo: " + userFind.username}</Text>
      </View>
      <Button color="#FF8D00" title="Déconnexion" onPress={signOut} />
    </ScreenContainer>
  );
};
