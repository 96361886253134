import React, { useState } from "react";
import {
  StyleSheet,
  View,
  TextInput,
  TouchableOpacity,
  Text,
  Button,
  ActivityIndicator,
} from "react-native";
// import FilmItem from "./FilmItem";
import client from "../utils/api";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: "center",
    // alignItems: "center",
  },
  textAddButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  input: {
    margin: 15,
    padding: 5,
    height: 40,
    width: "90%",
    borderColor: "#FF8D00",
    borderRadius: 8,
    borderWidth: 1,
  },
  submitButton: {
    backgroundColor: "#FF8D00",
    width: "20%",
    borderRadius: 8,
    padding: 10,
    margin: 15,
    height: 40,
  },

  submitButtonText: {
    color: "white",
  },
});

const ScreenContainer = ({ children }) => (
  <View style={styles.container}>{children}</View>
);

export function Search() {
  // return (
  //   <ScreenContainer>
  //     <Text>Ecran de recherche</Text>
  //   </ScreenContainer>
  // );
  const [searchEmail, setSearchEmail] = useState("");
  const [userFind, setUserFound] = useState({});
  const handleEmail = (text) => {
    setSearchEmail(text);
  };

  const search = async (email) => {
    const userEmail = await client.get("users/email/" + email);
    // console.log("userEmail", userEmail.data.firstname);
    setUserFound(userEmail.data);
  };

  return (
    <ScreenContainer>
      <View style={styles.textAndButton}>
        <TextInput
          style={styles.input}
          underlineColorAndroid="transparent"
          placeholder="Search email"
          placeholderTextColor="#FF8D00"
          autoCapitalize="none"
          onChangeText={handleEmail}
        />

        <TouchableOpacity
          style={styles.submitButton}
          onPress={() => search(searchEmail)}
        >
          <Text style={styles.submitButtonText}> Submit </Text>
        </TouchableOpacity>
      </View>

      <Text>{userFind.firstname ? "Prénom " + userFind.firstname : ""}</Text>
      <Text>{userFind.lastname ? "Nom " + userFind.lastname : ""}</Text>
      <Text>{userFind.username ? "Pseudo " + userFind.username : ""}</Text>
    </ScreenContainer>
  );
}
